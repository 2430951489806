import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import { rhythm, scale } from '../utils/typography'

import 'prismjs/themes/prism.css'
import '../../static/syntax-hl.css'
import 'katex/dist/katex.min.css'
import '../../static/global.css'

const IndexH1 = styled.h1`
  margin-bottom: ${rhythm(1.2)};
  margin-top: 0;
  padding-top: 35vh;

  @media only screen and (max-width: 480px) {
    text-align: right;
    max-width: ${rhythm(10)};
  }
`

const IndexH3 = styled.h3`
  font-family: 'Rosario', sans-serif;
  margin-top: 0;
  margin-bottom: ${rhythm(-1)};

  @media only screen and (max-width: 480px) {
    text-align: right;
  }
`

const Subtitle = styled.h3`
  font-family: 'Rosario', sans-serif;
  margin-top: 0;

  @media only screen and (max-width: 480px) {
    text-align: right;
    max-width: ${rhythm(10)};
  }
`

const HeaderDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(28)};
  padding-left: ${rhythm(1.15)};
  padding-right: ${rhythm(1.15)};
  padding-top: ${rhythm(1.15)};

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`

const ContentDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(30)};
  padding-bottom: ${rhythm(1.5)};
  padding-left: ${rhythm(1.15)};
  padding-right: ${rhythm(1.15)};
`

class Template extends React.Component {
  render() {
    const bgHsl = `hsl(50, 90%, 95%)`
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const contentsPath = `${__PATH_PREFIX__}/notes`
    let header
    const isRoot = location.pathname === rootPath
    const isContents = location.pathname.replace(/\/$/, '') === contentsPath

    if (isRoot) {
      header = (
        <>
          <IndexH1 style={{ ...scale(1.2) }}>
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              fizzbuzz
            </Link>
          </IndexH1>
        </>
      )
    } else if (isContents) {
      header = (
        <IndexH3>
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
            }}
            to={'/'}
          >
            fizzbuzz
          </Link>
        </IndexH3>
      )
    }
    return (
      <>
        <Helmet>
          <style>{`body { background-color: ${bgHsl}; }`}</style>
        </Helmet>
        <HeaderDiv>{header}</HeaderDiv>
        <ContentDiv>{children}</ContentDiv>
      </>
    )
  }
}

export default Template
